import './App.css';
import Layout from './Layout/Layout';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
     <ToastContainer />
      <Layout />
    </>
  );
}

export default App;
