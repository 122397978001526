import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import './ProductionSideBar.css'
import './ProductionSideBar.css'

const ProductionSideBar = ({ isMobileView = false }) => {
  const { pathname } = useLocation();
  const [ksrOpen, setKsrOpen] = useState(false);


  const isKsrSubmenuActive = () => {
    return ["/schedule3.1", "/schedule4.3", "/schedule5.1", "/schedule6.5"].includes(pathname);
  };

  useEffect(() => {

    if (isKsrSubmenuActive()) {
      setKsrOpen(true);
    }
  }, [pathname]);

  const sidebarItems = [
    {
      title: "Production Management",
      items: [
        {
          href: "/form10",
          icon: "flaticon-discovery",
          text: "FORM-10",
        },
        {
          href: "/form3",
          icon: "flaticon-chat-1",
          text: "FORM-3",
        },
        {
          href: "#",
          icon: "flaticon-electricity",
          text: "KSR PRODUCTION",
          submenu: [
            { href: "/schedule3.1", text: "Schedule 3.1" },
            { href: "/schedule4.3", text: "Schedule 4.3" },
            { href: "/schedule5.1", text: "Schedule 5.1" },
            { href: "/schedule6.5", text: "Schedule 6.5" },
            { href: "/schedule5.3", text: "Schedule 5.3" }
          ],
        },
        {
          href: "/form4",
          icon: "flaticon-garden",
          text: "FORM-4",
        },
      ],
    },
  ];

  const toggleKsrSubmenu = () => {
    setKsrOpen(!ksrOpen);
  };

  return (
    <div
      className={`dashboard__sidebar ${isMobileView ? "" : "d-none d-lg-block"
        }`}
    >
      <div className="dashboard_sidebar_list p-3">
        {sidebarItems.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <p
              className={`fz15 fw400 ff-heading ${sectionIndex === 0 ? "mt-0" : "mt30"
                }`}
              style={{ fontSize: "16px" }}
            >
              {section.title}
            </p>
            {section.items.map((item, itemIndex) => (
              <div key={itemIndex} className="sidebar_list_item mb-2" style={{ fontSize: "12px" }}>
                {item.submenu ? (
                  <div>
                    <div
                      onClick={toggleKsrSubmenu}
                      className={`d-flex align-items-center  ${isKsrSubmenuActive() ? "-is-active" : ""
                        }`}
                      style={{ cursor: "pointer", textDecoration: "none", color: "inherit", fontSize: "12 px", fontWeight: "600" }}
                    >
                      <i className={`${item.icon} mr15`} />
                      {item.text}
                    </div>
                    {ksrOpen &&
                      item.submenu.map((subItem, subItemIndex) => (
                        <div key={subItemIndex} className="ml-4">
                          <Link
                            to={subItem.href}
                            className={`d-flex align-items-center ${pathname === subItem.href ? "-is-active" : ""
                              }`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {subItem.text}
                          </Link>
                        </div>
                      ))}
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    className={`d-flex align-items-center ${pathname === item.href ? "-is-active" : ""
                      }`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className={`${item.icon} mr15`} />
                    {item.text}
                  </Link>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductionSideBar;
