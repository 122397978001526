import React, { useEffect, useState } from "react";
import "./Form3.css";
import ProductionTopBar from "../ProductionTopBar/ProductionTopBar";
import ProductionSideBar from "../ProductionSideBar/ProductionSideBar";
import axios from "axios";

const Form3 = () => {
  const [data, setData] = useState([]);

  const tableHeading = [
    {
      th1: "S.No",
      th2: "Year",
      th3: "Date",
      th4: "Received From",
      th5: "Supplier Name",
      th6: "Type of waste with category as per schedule,I, II & III of these rules ",
      th7: "Name of HAZ. Waste",
      th8: "Mainfest No",
      th9: "Sample ID",
      th10: "Qty in MTS",
      th11: "Method Of Storage",
      th12: "KPCB Online Manifest Status",
      th13: "GRN",
      th14: "Move Production",
    },
  ];

  useEffect(() => {
    axios
      .get("https://avatarapi.hazzino.com/api/po_form1")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.company_name.toLowerCase().includes("ksr")
        );
        setData(filteredData);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const getYearRange = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
    const endYear = startYear + 1;

    const startYearShort = startYear.toString().slice(-2);
    const endYearShort = endYear.toString().slice(-2);

    return `${startYearShort}-${endYearShort}`;
  };

  const [yearRange, setYearRange] = useState(getYearRange());

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <div className="formtopnav">
        <ProductionTopBar />
      </div>
      <div className="form3content">
        <div className="formtensidebar">
          <ProductionSideBar />
        </div>
        <div className="form3contents">
          <div className="py-3 form-head">
            <div className="container bgcolor">
              <div
                className="d-flex flex-column align-items-center form-heading"
                style={{ color: "White", lineHeight: "10px" }}
              >
                <h5>FORM-3</h5>
                <p>(See rule 6(5), 13(7), 14(6),16(5) and 0(1))</p>
                <p>
                  FORMAT FOR MAINTAINING RECORDS OF HAZARDOUS WASTES AND OTHER
                  WASTES
                </p>
                <p>
                  1.Name and Address of the Facility : AVATAR PETROCHEMICALS
                  PRIVATE LTD
                </p>
                <div style={{ textAlign: "justify" }}>
                  <p>
                    2.Date of issuance of authorization and its reference
                    number:
                  </p>
                  <p>
                    Tamilnadu PCB Authorization No: TNPCB HWA-AUTHORISATION No:
                    22HFC44917696 dated 30/05/2022,
                    T2/TNPCB/F.0323TEN/HWA/OS/TEN/2022 dated 30/05/2022
                  </p>
                  <p>
                    Kerala PCB Authorization No: PCB/HO/WW2/EKM-1/AUTH/11/2017,
                    PCB/HO/HWM/AUTH/08/2023 dated 30/03/2023
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 container d-flex justify-content-center report-form3">
            <div className="">
              <table
                className="table table-bordered form3heading"
                // style={{ borderColor: "black" }}
              >
                {tableHeading.map((tableHeadings) => (
                  <thead key="thead">
                    <tr className="thee-data" style={{ fontSize: "8px" }}>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th1}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th2}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th3}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th4}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th5}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th6}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th7}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th8}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th9}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th10}
                      </th>

                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th11}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle"
                      >
                        {tableHeadings.th12}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle grn"
                      >
                        {tableHeadings.th13}
                      </th>
                      <th
                        scope="col"
                        className=" text-align-justify align-middle movebut"
                      >
                        {tableHeadings.th14}
                      </th>
                    </tr>
                  </thead>
                ))}
                <tbody className="form3datas">
                  {data.map((formDetails, index) => (
                    <tr
                      className="align-middle text-align-center"
                      key={index}
                      style={{ fontSize: "7px" }}
                    >
                      <td>{index + 1}</td>
                      <td>{yearRange}</td>
                      <td>
                        {formDetails.sender_cert_date
                          ? new Date(
                              formDetails.sender_cert_date
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                          : "N/A"}
                      </td>
                      <td>{formDetails.sender_name}</td>
                      <td>{formDetails.ship_name}</td>
                      <td>
                        {formDetails.waste_description?.[0]?.label || "N/A"}
                      </td>
                      <td>{formDetails.waste_des2}</td>
                      <td>{formDetails.manifest_doc_no}</td>
                      <td>
                        {yearRange}/
                        {formDetails.sender_cert_date
                          ? new Date(
                              formDetails.sender_cert_date
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                          : "N/A"}
                        /{formDetails.sender_name}/{formDetails.ship_name}
                        {formDetails.cert_qty_kp}/
                        {formDetails.waste_description?.[0]?.label
                          ? formDetails.waste_description[0].label.replace(
                              /[^0-9.]/g,
                              ""
                            )
                          : "N/A"}
                        /{formDetails.waste_des2}/{formDetails.manifest_doc_no}
                      </td>
                      <td>{formDetails.eway_qty}</td>
                      <td>{formDetails.method_of_storage}</td>
                      <td>SUBMITTED</td>
                      <td className="grn">
                        <input type="text" placeholder="GRN" />
                      </td>

                      <td className="movebut">
                        <button
                        // onClick={() => handleMove(formDetails)}
                        // disabled={index !== data.length - 1}
                        >
                          Move
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Print button */}
          <div className="control-group form3-print">
            <button className="print-button3" onClick={handlePrint}>Print</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form3;
