import React, { useState } from "react";
import axios from "axios";
import "./Form10.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
import PrintableView from "../PrintableView/PrintableView";
import ProductionSideBar from "../ProductionSideBar/ProductionSideBar";
import ProductionSideBarPage from "../../Pages/ProductionSideBarPage";

const Form10 = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    sender_name: "",
    sender_address: "",
    ship_name: "",
    sender_auth_no: "",
    cert_qty: "",
    eway_qty: "",
    manifest_doc_no: "",
    transporter_name: "",
    vehicle: "",
    transporter_reg_no: "",
    vehicle_reg_no: "",
    receiver_name: "",
    receiver_auth_no: "",
    waste_description: [],
    waste_des2: "",
    total_qty: "",
    method_of_storage: "",
    physical_form: "",
    sender_cert_date: "",
    transporter_ack_receipt_wastes_name_stamp: "",
    transporter_ack_receipt_wastes_signature: "",
    transporter_ack_receipt_wastes_date: "",
    receiver_certificate_name_stamp: "",
    receiver_certificate_signature: "",
    receiver_certificate_date: "",
  });
  
  // ----------------waste schedule------------------ 

  const options = [
    { label: "Schedule 3.1", value: "Schedule 3.1" },
    { label: "Schedule 4.3", value: "Schedule 4.3" },
    { label: "Schedule 5.1", value: "Schedule 5.1" },
    { label: "Schedule 5.3", value: "Schedule 5.3" },
    { label: "Schedule 20.2", value: "Schedule 20.2" },
  ];

  // ----------------------year range---------------------

  // const getYearRange = () => {
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   const currentMonth = currentDate.getMonth() + 1;

  //   const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
  //   const endYear = startYear + 1;

  //   const startYearShort = startYear.toString().slice(-2);
  //   const endYearShort = endYear.toString().slice(-2);

  //   return ${startYearShort}-${endYearShort};
  // };
  // console.log(getYearRange());

  // --------------------use states-----------------------

  const [isPrintable, setIsPrintable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [suggestions, setSuggestions] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  const [unit, setUnit] = useState("mts");
  // const [yearRange, setYearRange] = useState(getYearRange());

  const handleChange = (e) => {
    const { name, value, type, files, id } = e.target;

    if (id === "senderName" || id === "senderAddress" || id === "ship_name") {
      const senderNameInput = document.getElementById("senderName").value;
      const senderAddressInput = document.getElementById("senderAddress").value;
      const senderShipInput = document.getElementById("ship_name").value;

      setFormData((prevInfo) => ({
        ...prevInfo,
        sender_name: senderNameInput,
        ship_name: senderShipInput,
        sender_address: senderAddressInput
      }));
    } else {
      setFormData((prevInfo) => {
        if (type === "file") {
          return {
            ...prevInfo,
            [name]: files[0],
          };
        } else if (type === "date") {
          return {
            ...prevInfo,
            [name]: value,
          };
        } else {
          return {
            ...prevInfo,
            [name]: value,
          };
        }
      });
    }
  };

  const handleWasteDescriptionChange = (selectedValues) => {
    const selectedWasteDescriptions = selectedValues.map((option) => ({
      label: option.label,
      value: option.value,
    }));
    console.log(selectedWasteDescriptions);
    setFormData((prevInfo) => ({
      ...prevInfo,
      waste_description: selectedWasteDescriptions,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const senderNameInput = document.getElementById("senderName").value;
    const senderShipInput = document.getElementById("ship_name").value;
    const senderAddressInput = document.getElementById("senderAddress").value;
  
    if (!senderNameInput.trim() || !senderShipInput.trim()) {
      toast.error("Sender's Name, Ship Name cannot be empty.");
      return; 
    }
  
    const certQtyWithUnit = `${formData.cert_qty} ${unit}`;
    if (parseFloat(formData.eway_qty) > parseFloat(formData.cert_qty)) {
      toast.error("Eway Quantity cannot be greater than Certificate Quantity.");
      return; 
    }
  
    if (isSubmitting) return;
  
    setIsSubmitting(true);
  
    const dataToSubmit = {
      ...formData,
      cert_qty: certQtyWithUnit,
      sender_address: senderAddressInput.trim() === "" ? null : senderAddressInput,
    };
  
    const formDataToSubmit = new FormData();
    Object.keys(dataToSubmit).forEach((key) => {
      if (key === "waste_description") {
        dataToSubmit[key].forEach((waste, index) => {
          formDataToSubmit.append(`${key}[${index}][label], waste.label`);
          formDataToSubmit.append(`${key}[${index}][value], waste.value`);
        });
      } else {
        formDataToSubmit.append(key, dataToSubmit[key]);
      }
    });
  
    try {
      const response = await axios.post(
        "https://avatarapi.hazzino.com/api/po_form1",
        formDataToSubmit
      );
  
      if (response.status === 200) {
        toast.success("Form submitted successfully. Now print..!");
        setIsPrintable(true);
      } else {
        toast.error("Form submission failed");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Submission failed: ${error.response.data.message}`);
      } else {
        toast.error(`Submission failed: ${error.message}`);
      }
      console.error("Error submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="formten">
      <div className="formtopnav">
        <ProductionSideBarPage />
      </div>
      <div className="formtencontent">
        <div className="formtensidebar">
          <ProductionSideBar />
        </div>

        <div className="formtencontents">
          {isPrintable ? (
            <PrintableView formData={formData} />
          ) : (
            <div className="FormFIll">
              <div className="box">
                <h1>Form 10</h1>
                <h1>[See Rule 19(1)]</h1>
                <h1>MANIFEST FOR HAZARDOUS AND OTHER WASTE</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group1">
                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="company_name">1. Company Name :</label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        placeholder="company_name"
                        onChange={handleChange}
                        className="form-control mb-2"
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="senderName">
                        2. Sender’s Name and Mailing Address(including PhoneNo.
                        and e-mail):
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            id="senderName"
                            name="sender_name"
                            placeholder="Name"
                            onChange={handleChange}
                            className="form-control mb-2"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            id="ship_name"
                            name="ship_name"
                            placeholder="Shipname"
                            onChange={handleChange}
                            className="form-control mb-2"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            id="senderAddress"
                            name="sender_address"
                            placeholder="Address, Phone No, Email"
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="sender_auth_no">
                        3. Sender’s Authorisation No. :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Sender’s Authorisation No."
                        name="sender_auth_no"
                        id="sender_auth_no"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="cert_qty">
                        4. Certificate Quantity :
                      </label>
                    </div>
                    <div className="input col-md-6 d-flex">
                      <div className="col-md-6">
                        <input
                          className="field-cert"
                          type="text"
                          id="cert_qty"
                          name="cert_qty"
                          value={formData.cert_qty}
                          onChange={handleChange}
                          placeholder="Certificate Qty"
                        />
                      </div>
                      <div className="col-md-6">
                        <select
                          className="cert_select"
                          name="unit"
                          id="unit"
                          onChange={(e) => setUnit(e.target.value)} 
                          value={unit}
                        >
                          <option value="MTS">MTS</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="eway_qty">5. Eway Quantity :</label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        type="text"
                        id="eway_qty"
                        name="eway_qty"
                        value={formData.eway_qty}
                        onChange={handleChange}
                        placeholder="Eway Qty"
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="manifest_doc_no">
                        6. Manifest Document No. :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        type="text"
                        id="manifest_doc_no"
                        name="manifest_doc_no"
                        onChange={handleChange}
                        placeholder="Manifest Document No"
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="transporter_name">
                        7. Transporter’s name & address (including Phone No. and
                        e-mail):
                      </label>
                    </div>
                    <div className="input col-md-6 ">
                      <select
                        className="border-trans"
                        name="transporter_name"
                        id="transporter_name"
                        onChange={handleChange}
                      >
                        <option value="">Select Transporter</option>
                        <option
                          value="AVATAR PETROCHEMICALS PRIVATE LIMITED THENI,9894047412
                          avatarpetro@gmail.com"
                        >
                          AVATAR PETROCHEMICALS PRIVATE LIMITED THENI,9894047412
                          avatarpetro@gmail.com
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="vehicle">8. Type of Vehicle :</label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Type of Vehicle"
                        type="text"
                        id="vehicle"
                        name="vehicle"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="transporter_reg_no">
                        9. Transporter’s Registration No. :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Transporter’s Registration No"
                        name="transporter_reg_no"
                        id="transporter_reg_no"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="vehicle_reg_no">
                        10. Vehicle Registration No. :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Vehicle Registration No"
                        name="vehicle_reg_no"
                        id="vehicle_reg_no"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="receiver_name">
                        11. Receiver’s name & mailing address (including Phone
                        No. and e-mail):
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <select
                        className="border-trans"
                        name="receiver_name"
                        id="receiver_name"
                        onChange={handleChange}
                      >
                        <option value="">Select Receiver</option>
                        <option
                          value="AVATAR PETROCHEMICALS PRIVATE LIMITED THENI,9894047412
                          avatarpetro@gmail.com"
                        >
                          AVATAR PETROCHEMICALS PRIVATE LIMITED THENI,9894047412
                          avatarpetro@gmail.com
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="receiver_name">
                        12. Receiver’s authorisation No:
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Receiver’s authorisation No"
                        name="receiver_auth_no"
                        id="receiver_auth_no"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="waste_description">
                        14. Type of waste (category as per schedule):
                      </label>
                    </div>
                    <div className="col-md-6">
                      <Select
                        name="waste_description"
                        id="waste_description"
                        options={options}
                        multi
                        onChange={handleWasteDescriptionChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="waste_description">
                        14. Waste Description:
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Waste Description"
                        name="waste_des2"
                        id="waste_des2"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="total_qty">16. No of Containers :</label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="No of Containers"
                        type="text"
                        name="total_qty"
                        id="total_qty"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="method_of_storage">
                        17. Method of Storage :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Method of Storage"
                        name="method_of_storage"
                        id="method_of_storage"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="physical_form">18. Physical Form :</label>
                    </div>
                    <div className="input col-md-6">
                      <input
                        placeholder="Physical Form"
                        name="physical_form"
                        id="physical_form"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-6">
                      <label htmlFor="sender_certificate">
                        19. Sender’s Certificate :
                      </label>
                    </div>
                    <div className="input col-md-6">
                      I here by declare that the contents of the consignment are
                      fully and accurately described above by proper shipping
                      name and are categorised, packed, marked and labelled, and
                      are in all respects in proper conditions for transport by
                      road according to applicable national government
                      regulations
                    </div>
                    <div className="input-group row justify-content-end pe-3 ">
                      <div className="row col-md-3 m-1 me-5" >
                        <div className="input col-md-12">
                          <input
                            type="date"
                            name="sender_cert_date"
                            id="sender_cert_date"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="sender_cert_date">Date</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-12">
                      <label htmlFor="transporter_ack_receipt_wastes_name_stamp">
                        20. Transporter’s Acknowledgement of receipt of Wastes:
                      </label>
                    </div>
                    <div className="input-group row">
                      <div className="row col-md-4 m-1">
                        <div className="input col-md-12">
                          <input
                            type="file"
                            name="transporter_ack_receipt_wastes_name_stamp"
                            id="transporter_ack_receipt_wastes_name_stamp"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="transporter_ack_receipt_wastes_name_stamp">
                            Name & Stamp
                          </label>
                        </div>
                      </div>

                      <div className="row col-md-4 m-1">
                        <div className="input col-md-12">
                          <input
                            type="file"
                            name="transporter_ack_receipt_wastes_signature"
                            id="transporter_ack_receipt_wastes_signature"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="transporter_ack_receipt_wastes_signature">
                            Signature
                          </label>
                        </div>
                      </div>

                      <div className="row col-md-3 m-1">
                        <div className="input col-md-12">
                          <input
                            type="date"
                            name="transporter_ack_receipt_wastes_date"
                            id="transporter_ack_receipt_wastes_date"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="transporter_ack_receipt_wastes_date">
                            Date
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-group row">
                    <div className="label col-md-12">
                      <label htmlFor="receiver_certificate_name_stamp">
                        21. Receiver’s Certification for receipt of hazardous
                        and other wastes:
                      </label>
                    </div>
                    <div className="input-group row">
                      <div className="row col-md-4 m-1">
                        <div className="input col-md-12">
                          <input
                            type="file"
                            name="receiver_certificate_name_stamp"
                            id="receiver_certificate_name_stamp"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="receiver_certificate_name_stamp">
                            Name & Stamp
                          </label>
                        </div>
                      </div>

                      <div className="row col-md-4 m-1">
                        <div className="input col-md-12">
                          <input
                            type="file"
                            name="receiver_certificate_signature"
                            id="receiver_certificate_signature"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="receiver_certificate_signature">
                            Signature
                          </label>
                        </div>
                      </div>

                      <div className="row col-md-3 m-1">
                        <div className="input col-md-12">
                          <input
                            type="date"
                            name="receiver_certificate_date"
                            id="receiver_certificate_date"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="label col-md-12">
                          <label htmlFor="receiver_certificate_date">
                            Date
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="control-group1">
                    <button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Submitting..." : "Save & Print"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form10;
